'use client';

import { Box } from 'ui/components/box';
import { Typography } from 'ui/components/typography';
import { usePromoCode } from '../promo/use-promo-code';

export function PromoCodeBanner() {
  const { code } = usePromoCode();
  return code ? (
    <Box css={{ background: 'esa.secondary', padding: 1, width: '100%' }} layout='centeredColumn'>
      <Typography css={{ color: 'esa.on_secondary !important' }} type='subtitle2'>
        Promo code activated: {code}
      </Typography>
    </Box>
  ) : null;
}
