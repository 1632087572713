'use client';

import { DropdownMenu as RadixDropdownMenu } from '../radix-ui/dropdown-menu';
import { SystemStyleObject } from '../styled-system/types';
import { css } from '../styled-system/css';

type Css = { css?: SystemStyleObject };

type DropdownMenuProps = {
  className?: string;
  children: React.ReactNode;
  trigger: React.ReactNode;
} & Css;

export function DropdownMenu({ children, className, css: cssObject = {}, trigger }: DropdownMenuProps) {
  return (
    <RadixDropdownMenu.Root>
      <RadixDropdownMenu.Trigger asChild className={className}>
        {trigger}
      </RadixDropdownMenu.Trigger>

      <RadixDropdownMenu.Portal>
        <div className={css({ ...cssObject })}>{children}</div>
      </RadixDropdownMenu.Portal>
    </RadixDropdownMenu.Root>
  );
}

DropdownMenu.Root = RadixDropdownMenu.Root;
DropdownMenu.Trigger = RadixDropdownMenu.Trigger;
DropdownMenu.Portal = RadixDropdownMenu.Portal;
DropdownMenu.Content = RadixDropdownMenu.Content;
DropdownMenu.Arrow = RadixDropdownMenu.Arrow;
DropdownMenu.Item = RadixDropdownMenu.Item;
DropdownMenu.Group = RadixDropdownMenu.Group;
DropdownMenu.Label = RadixDropdownMenu.Label;
DropdownMenu.CheckboxItem = RadixDropdownMenu.CheckboxItem;
DropdownMenu.RadioGroup = RadixDropdownMenu.RadioGroup;
DropdownMenu.RadioItem = RadixDropdownMenu.RadioItem;
DropdownMenu.ItemIndicator = RadixDropdownMenu.ItemIndicator;
DropdownMenu.Separator = RadixDropdownMenu.Separator;
DropdownMenu.Sub = RadixDropdownMenu.Sub;
DropdownMenu.SubTrigger = RadixDropdownMenu.SubTrigger;
DropdownMenu.SubContent = RadixDropdownMenu.SubContent;
