'use client';

import { useEffect, useState } from 'react';

import { Box } from './box';
import { useFreezeScroll } from '../hooks/use-freeze-scroll';
import { useKeydown } from '../hooks/use-keydown';

type ModalProps = { button: React.ReactNode; children: React.ReactNode; defaultOpen?: boolean };

export function Modal({ button, children, defaultOpen = false }: ModalProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  useFreezeScroll(isOpen);

  useKeydown({
    isActive: isOpen,
    callback: (e) => {
      const keyboardEvent = e as KeyboardEvent;

      keyboardEvent.key === 'Escape' && setIsOpen(false);
    },
  });

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('freeze-scroll');
    } else {
      document.body.classList.remove('freeze-scroll');
    }
  }, [isOpen]);

  return (
    <>
      <span onClick={() => setIsOpen(true)}>{button}</span>
      {isOpen && (
        <Box
          css={{
            position: 'fixed',
            inset: '0',
            zIndex: 'overlay',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

            background: 'm3.scrim090',
          }}
          onClick={() => setIsOpen(false)}
        >
          {children}
        </Box>
      )}
    </>
  );
}
