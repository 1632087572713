import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';

import { css, cva, cx } from '../styled-system/css';

import { Box } from '../components/box';
import { SystemStyleObject } from '../styled-system/types';
import { typography } from '../components/typography';

type Css = { css?: SystemStyleObject };

function Content({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuContentProps & Css) {
  return (
    <RadixDropdownMenu.Content
      className={cx(
        typography({ type: 'body2' }),
        css({ background: 'elevated.background', borderRadius: 'sm', zIndex: 'menu', ...cssObject })
      )}
      {...props}
    >
      {children}
    </RadixDropdownMenu.Content>
  );
}

function SubContent({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuSubContentProps & Css) {
  return (
    <RadixDropdownMenu.SubContent
      className={cx(
        typography({ type: 'body2' }),
        css({ background: 'elevated.background', borderRadius: 'sm', zIndex: 'menu', ...cssObject })
      )}
      {...props}
    >
      {children}
    </RadixDropdownMenu.SubContent>
  );
}

const item = cva({
  base: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '3',
    paddingY: '3',
    paddingRight: '3',

    cursor: 'pointer',
    _disabled: {
      opacity: 0.5,
      cursor: 'default',
      pointerEvents: 'none',
    },
    _hover: {
      background: 'elevated.background.hover',
    },
  },
  defaultVariants: { columns: 'double' },
  variants: {
    columns: {
      single: { paddingLeft: '3' },
      double: {
        paddingLeft: '10',
      },
    },
  },
});
type ItemVariants = NonNullable<Parameters<typeof item>['0']>;

function Item({
  children,
  columns,
  css: cssObject = {},
  ...props
}: RadixDropdownMenu.MenuItemProps & ItemVariants & Css) {
  return (
    <RadixDropdownMenu.Item className={cx(item({ columns }), css(cssObject))} {...props}>
      {children}
    </RadixDropdownMenu.Item>
  );
}
function Label({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuLabelProps & Css) {
  return (
    <RadixDropdownMenu.Label
      className={cx(
        css({
          paddingTop: '3',
          paddingBottom: '0',
          paddingRight: '3',
          paddingLeft: '10',
          fontWeight: 'semibold',
          ...cssObject,
        })
      )}
      {...props}
    >
      {children}
    </RadixDropdownMenu.Label>
  );
}

function SubTrigger({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuSubTriggerProps & Css) {
  return (
    <RadixDropdownMenu.SubTrigger
      className={cx(
        css({
          display: 'flex',
          justifyContent: 'space-between',
          gap: '3',
          paddingY: '3',
          paddingRight: '3',
          paddingLeft: '10',
          cursor: 'pointer',
          _hover: {
            background: 'elevated.background.hover',
          },
          ...cssObject,
        })
      )}
      {...props}
    >
      {children}
    </RadixDropdownMenu.SubTrigger>
  );
}

function CheckboxItem({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuCheckboxItemProps & Css) {
  return (
    <RadixDropdownMenu.CheckboxItem
      className={css({
        position: 'relative',
        display: 'flex',
        justifyContent: 'space-between',
        gap: '3',
        paddingY: '3',
        paddingRight: '3',
        paddingLeft: '10',
        cursor: 'pointer',
        _hover: {
          background: 'elevated.background.hover',
        },
        ...cssObject,
      })}
      {...props}
    >
      {children}
    </RadixDropdownMenu.CheckboxItem>
  );
}

function RadioItem({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuRadioItemProps & Css) {
  return (
    <RadixDropdownMenu.RadioItem
      className={css({
        position: 'relative',
        paddingY: '3',
        paddingRight: '3',
        paddingLeft: '10',
        cursor: 'pointer',
        _hover: {
          background: 'elevated.background.hover',
        },
        ...cssObject,
      })}
      {...props}
    >
      {children}
    </RadixDropdownMenu.RadioItem>
  );
}

function ItemIndicator({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuItemIndicatorProps & Css) {
  return (
    <Box
      css={{
        position: 'absolute',
        top: '0',
        left: '3',
        bottom: '0',
        width: '1rem',

        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        ...cssObject,
      }}
    >
      <RadixDropdownMenu.ItemIndicator {...props}>{children}</RadixDropdownMenu.ItemIndicator>
    </Box>
  );
}

function Separator({ children, css: cssObject = {}, ...props }: RadixDropdownMenu.MenuSeparatorProps & Css) {
  return (
    <RadixDropdownMenu.Separator
      className={css({
        borderBottom: '1px solid token(colors.elevated.borderColor)',
        ...cssObject,
      })}
      {...props}
    >
      {children}
    </RadixDropdownMenu.Separator>
  );
}

export const DropdownMenu = {
  Root: RadixDropdownMenu.Root,
  Trigger: RadixDropdownMenu.Trigger,
  Portal: RadixDropdownMenu.Portal,
  Content,
  Arrow: RadixDropdownMenu.Arrow,
  Item,
  Group: RadixDropdownMenu.Group,
  Label,
  CheckboxItem,
  RadioGroup: RadixDropdownMenu.RadioGroup,
  RadioItem,
  ItemIndicator,
  Separator,
  Sub: RadixDropdownMenu.Sub,
  SubTrigger,
  SubContent,
};
