'use client';

import { AuthProvider, useAuth } from 'ui/providers/auth-provider';
import { useEffect, useState } from 'react';

import { Box } from 'ui/components/box';
import { Button } from 'ui/components/button';
import { CONSTANTS } from '../../constants';
import { DropdownMenu } from 'ui/components/dropdown-menu';
import { FirebaseProvider } from 'ui/providers/firebase-provider';
import { HighlightIdentify } from './highlight-identify';
import { Icon } from '../../components/icon';
import { IconButton } from '../../components/icon-button';
import { Link } from 'ui/components/link';
import { Logotype } from './logotype';
import NextLink from 'next/link';
import { Provider } from '../providers/schemas';
import { getProvider } from '../../utils/get-provider';
import { usePathname } from 'next/navigation';

type NavProps = {
  adminLink?: React.ReactElement;
};

export function Nav(props: NavProps) {
  return (
    <FirebaseProvider>
      <AuthProvider>
        <NavConnected {...props} />
        <HighlightIdentify />
      </AuthProvider>
    </FirebaseProvider>
  );
}

function NavConnected({ adminLink }: NavProps) {
  const { isPartner, user, userId, signOut } = useAuth();
  const [provider, setProvider] = useState<Provider | null>(null);
  const isLoggedIn = !!userId;
  const pathname = usePathname();
  const isDashboardPath = pathname.includes('dashboard');
  const isAccountPath = pathname.includes('account');
  const isLoginPath = pathname.includes('login');
  const isAdminPath = pathname.includes('admin');
  const isPartnerPath = pathname.includes('partner');

  useEffect(() => {
    user?.email && getProvider(user.email).then((provider) => setProvider(provider));
  }, [user]);

  return (
    <Box
      as='nav'
      css={{
        width: ['100%', 'width.child'],
        maxWidth: ['maxWidth.child.mobile', 'maxWidth.child.sm'],
        paddingY: 3,
        gap: 3,
        '& .hide-narrow': {
          display: ['none', 'none', 'flex'],
        },
        '& .show-narrow': {
          display: ['flex', 'flex', 'none'],
        },
        '& a, & button': {
          minWidth: [null, null, '7rem'],
        },
      }}
      layout='centeredRow'
    >
      <Box css={{ flex: 1 }}>
        <NextLink href='/'>
          <Logotype />
        </NextLink>
      </Box>
      {isLoggedIn ? (
        <>
          <Button className='hide-narrow' onClick={() => signOut()} variant='text'>
            Logout
          </Button>

          {isPartner && !isPartnerPath && (
            <Link buttonVariant='outlined' className='hide-narrow' href='/user/partner/affiliate'>
              Affiliate
            </Link>
          )}

          {isDashboardPath ? (
            <Link buttonVariant='filled' className='hide-narrow' href='/user/account'>
              Account
            </Link>
          ) : provider ? (
            provider.states.map((state) => (
              <Link
                buttonVariant='filled'
                className='hide-narrow'
                href={`/admin/${state}`}
                key={state}
                style={{ textTransform: 'capitalize' }}
              >
                {state}
              </Link>
            ))
          ) : (
            <Link buttonVariant='filled' className='hide-narrow' href='/user/dashboard'>
              Dashboard
            </Link>
          )}

          {isAdminPath ? null : (
            <Box
              className='hide-narrow'
              css={{ display: 'flex', flexWrap: 'wrap', gap: 4, '& button': { minWidth: '0 !important' } }}
            >
              <AdminDropdown adminLink={adminLink} />
            </Box>
          )}
        </>
      ) : isLoginPath ? (
        <Link href='/'>
          <Button className='hide-narrow'>Back</Button>
        </Link>
      ) : (
        <>
          <Link href='/user/login'>
            <Button className='hide-narrow' iconAfter={<Icon name='arrow_circle_right' size='2xl' />} variant='filled'>
              Log in
            </Button>
          </Link>
        </>
      )}

      <NavDropdown
        adminLink={adminLink}
        isAdmin={isAdminPath}
        isDashboard={isDashboardPath}
        isLoggedIn={isLoggedIn}
        isLogin={isLoginPath}
      />
    </Box>
  );
}

function NavDropdown({
  adminLink,
  isAdmin,
  isDashboard,
  isLoggedIn,
  isLogin,
}: {
  adminLink: NavProps['adminLink'];
  isAdmin: boolean;
  isDashboard: boolean;
  isLoggedIn: boolean;
  isLogin: boolean;
}) {
  const { signOut } = useAuth();

  return (
    <DropdownMenu className='show-narrow' trigger={<IconButton name='menu' size='2xl' />}>
      <DropdownMenu.Content
        align='end'
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderRadius: 'md',
          padding: 2,

          '& a': {
            display: 'flex',
            justifyContent: 'center',
          },

          '& [role="menuitem"]': {
            fontWeight: '500',
            fontSize: ['md', 'md', 'md', 'lg'],
            paddingLeft: 4,
          },
        }}
        sideOffset={8}
      >
        {isLoggedIn ? (
          <>
            {isDashboard ? (
              <Link className='hide-narrow' href='/user/account'>
                <Button size='sm' variant='filled'>
                  <DropdownMenu.Item>Account</DropdownMenu.Item>
                </Button>
              </Link>
            ) : (
              <Link className='hide-narrow' href='/user/dashboard'>
                <Button size='sm' variant='filled'>
                  <DropdownMenu.Item>Dashboard</DropdownMenu.Item>
                </Button>
              </Link>
            )}
            <div>
              <DropdownMenu.Item onClick={() => signOut()}>
                <Button>Logout</Button>
              </DropdownMenu.Item>
            </div>
            <Box css={{ gap: 2 }} layout='centeredColumn'>
              {adminLink}
            </Box>
          </>
        ) : isLogin ? (
          <Link href='/'>
            <DropdownMenu.Item>Cancel</DropdownMenu.Item>
          </Link>
        ) : (
          <>
            <Link href='/user/login'>
              <DropdownMenu.Item>Login</DropdownMenu.Item>
            </Link>
          </>
        )}
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}

function AdminDropdown({ adminLink }: { adminLink: NavProps['adminLink'] }) {
  return (
    <DropdownMenu trigger={<IconButton name='settings' size='2xl' />}>
      <DropdownMenu.Content
        align='end'
        css={{
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          borderRadius: 'md',
          padding: 2,

          '& a': {
            display: 'flex',
            justifyContent: 'center',
          },

          '& [role="menuitem"]': {
            fontWeight: '500',
            fontSize: ['md', 'md', 'md', 'lg'],
            paddingLeft: 4,
          },
        }}
        sideOffset={8}
      >
        <Box css={{ gap: 2 }} layout='centeredColumn'>
          {adminLink}
        </Box>
      </DropdownMenu.Content>
    </DropdownMenu>
  );
}
