import { useCallback } from 'react';
import { useLocalforage } from 'ui/hooks/use-localforage';

export const PROMO_CODE_KEY = 'promo_code';

export function usePromoCode() {
  const {
    values: [code],
    setItem,
    store,
  } = useLocalforage<[string]>([PROMO_CODE_KEY]);
  const setCode = useCallback((code: string) => setItem(PROMO_CODE_KEY, code), [setItem]);

  return { code, setCode, isReady: !!store };
}
