import { ButtonProps, button, getIconVariant } from './button';
import { css, cx } from '../styled-system/css';

import NextLink from 'next/link';
import { SystemStyleObject } from '../styled-system/types';

type NextLinkProps = Parameters<typeof NextLink>[0] & { children: React.ReactNode; css?: SystemStyleObject };

const UNDERLINED = css({ textDecoration: 'underline' });

export interface LinkProps extends NextLinkProps {
  blank?: boolean;
  css?: SystemStyleObject;
  disabled?: boolean;
  beforeIcon?: React.ReactNode;
  afterIcon?: React.ReactNode;
  buttonVariant?: ButtonProps['variant'];
  underlined?: boolean;
}

export function Link({
  blank,
  className,
  children = null,
  css: cssObject = {},
  disabled = false,
  beforeIcon = null,
  afterIcon = null,
  buttonVariant: variant,
  underlined = false,
  ...props
}: LinkProps) {
  const iconVariant = getIconVariant({ icon: beforeIcon || afterIcon, variant });
  const blankProps = blank ? { target: '_blank', rel: 'noopener noreferrer' } : {};

  return (
    <NextLink
      className={cx(
        className,
        variant ? button({ icon: iconVariant, variant }) : null,
        // css({ '&:visited': { color: 'inherit' } }),
        css({ pointerEvents: disabled ? 'none' : 'all', ...cssObject }),
        underlined ? UNDERLINED : null
      )}
      {...blankProps}
      {...props}
    >
      {beforeIcon && <div key='beforeIcon'>{beforeIcon}</div>}
      {children && <div key='children'>{children}</div>}
      {afterIcon && <div key='afterIcon'>{afterIcon}</div>}
    </NextLink>
  );
}
