import { H } from '@highlight-run/next/client';
import { useAuth } from 'ui/providers/auth-provider';
import { useEffect } from 'react';

export function HighlightIdentify() {
  const { user } = useAuth();

  useEffect(() => {
    if (user?.email) {
      H.identify(user.email);
    }
  }, [user]);
  return null;
}
