'use client';

import { AuthProvider, useAuth } from 'ui/providers/auth-provider';
import { LOCALFORAGE_KEY_QUIZZES, LOCALFORAGE_KEY_QUIZ_ACTIVE } from './quiz';
import { collection, deleteDoc, doc, getDocs, onSnapshot, runTransaction, setDoc } from 'firebase/firestore';
import { getQuizAnswers, parseQuizState } from './quiz-schema';
import { useEffect, useRef, useState } from 'react';
import { usePathname, useRouter } from 'next/navigation';

import { Box } from 'ui/components/box';
import { Button } from 'ui/components/button';
import { Link } from 'ui/components/link';
import { Modal } from 'ui/components/modal';
import { QuizState } from '../../providers/schemas';
import { Typography } from 'ui/components/typography';
import posthog from 'posthog-js';
import { useFirestore } from 'ui/hooks/use-firestore';
import { useLocalforage } from 'ui/hooks/use-localforage';
import { usePromoCode } from '../../promo/use-promo-code';

const LOCALFORAGE_KEY_FORWARD_TO_DASHBOARD_KEY = 'forward-to-dashboard';
export const LOCALFORAGE_PAYING_KEY = 'paying';

export function QuizSynchronizer() {
  return (
    <AuthProvider>
      <QuizSynchronizerConnected />
    </AuthProvider>
  );
}

function QuizSynchronizerConnected() {
  const pathname = usePathname();
  const isLogin = pathname.includes('login');
  const router = useRouter();
  const firestore = useFirestore();
  const { code: promotionCode } = usePromoCode();

  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const {
    values: [localQuizzes, shouldForwardToDashboard, paying],
    setItem,
    store,
  } = useLocalforage<[QuizState[], boolean, boolean]>([
    LOCALFORAGE_KEY_QUIZZES,
    LOCALFORAGE_KEY_FORWARD_TO_DASHBOARD_KEY,
    LOCALFORAGE_PAYING_KEY,
  ]);
  const { user, userId } = useAuth();

  useEffect(() => {
    // Listen to changes in the user's quizzes while paying
    if (!firestore) return;

    if (paying) {
      return onSnapshot(collection(firestore, `users/${userId}/quizzes`), async (querySnapshot) => {
        const quizActive = store && (await store.getItem(LOCALFORAGE_KEY_QUIZ_ACTIVE));
        const quizzes = querySnapshot.docs.map((doc) => parseQuizState(doc.data()));
        const quiz = quizzes.find((q) => q.id === quizActive);
        const isPaid = quiz?.paid;

        console.info('quizzes loaded:');
        console.table(quizzes);

        if (quizzes) {
          await setItem(LOCALFORAGE_KEY_QUIZZES, quizzes);
        }

        if (isPaid) {
          setItem(LOCALFORAGE_PAYING_KEY, false);
          setItem(LOCALFORAGE_KEY_QUIZ_ACTIVE, undefined);

          // Kick off the cron job to update assignments... AFTER the payment is complete
          fetch('/cron/update-assignments');

          posthog.capture('quiz_payment_complete', {
            quiz_id: quiz.id,
            user_email: user?.email || 'missing email',
          });
        }
      });
    } else {
      userId &&
        getDocs(collection(firestore, `/users/${userId}/quizzes`)).then(async (snapshot) => {
          const quizzes = snapshot.docs.map((doc) => parseQuizState(doc.data()));

          if (quizzes) {
            setItem(LOCALFORAGE_KEY_QUIZZES, quizzes);
          }

          // Reset active quiz id if it's paid
          const activeQuizId = store && (await store.getItem(LOCALFORAGE_KEY_QUIZ_ACTIVE));

          if (activeQuizId) {
            const quiz = quizzes.find((q) => q.id === activeQuizId);

            if (quiz?.paid) {
              store.setItem(LOCALFORAGE_KEY_QUIZ_ACTIVE, undefined);
            }
          }
        });
    }
  }, [firestore, paying, setItem, store, user?.email, userId]);

  useEffect(() => {
    // // @ts-ignore
    // window.resetQuiz = async () => {
    //   const quizState = parseQuizState({ ...localQuizState, saved: undefined });
    //   const result = await setItem(LOCALFORAGE_KEY, quizState);

    //   console.info('resetQuiz', result);
    // };

    (async () => {
      const quizzes = (localQuizzes || []).map((quiz) => parseQuizState(quiz));

      async function syncDoc({
        force,
        quizId,
        saved,
        shouldSync,
      }: {
        force: boolean;
        quizId: string;
        saved?: string;
        shouldSync: boolean;
      }) {
        if ((shouldSync || force) && firestore) {
          saved = new Date().toISOString();
          const quizState = quizzes.find((q) => q.id === quizId) as QuizState;
          const { state } = getQuizAnswers(quizState);

          const savedQuizState = parseQuizState({ ...quizState, promotionCode, state: state.toLowerCase(), saved });
          const anonymousDoc = doc(firestore, `users/anonymous/quizzes/${quizState.id}`);

          if (userId) {
            /**
             * Test procedure:
             * 1. Log out
             * 2. Create a quiz
             * 3. Log in
             */
            await runTransaction(firestore, async (transaction) => {
              transaction.set(doc(firestore, `users/${userId}/quizzes/${quizState.id}`), savedQuizState);

              if (force) {
                transaction.delete(anonymousDoc);
              }
            });
          } else {
            await setDoc(anonymousDoc, savedQuizState);
          }

          const updatedQuizzes = quizzes.map((q) => (q.id === quizState.id ? savedQuizState : q));
          await setItem(LOCALFORAGE_KEY_QUIZZES, updatedQuizzes);
        }
      }

      quizzes.forEach(async (quizState) => {
        const shouldSync = !!quizState.submitted && (!quizState.saved || quizState.saved < quizState.submitted);
        let saved = quizState.saved;

        await syncDoc({ force: false, quizId: quizState.id, saved, shouldSync });

        if (shouldSync && !userId && !isLogin) {
          console.info('showing login prompt', user);
          setShowLoginPrompt(true);

          await setItem(LOCALFORAGE_KEY_FORWARD_TO_DASHBOARD_KEY, true);
        } else if (saved && !quizState.paid && userId && shouldForwardToDashboard) {
          await setItem(LOCALFORAGE_KEY_FORWARD_TO_DASHBOARD_KEY, false);
          await syncDoc({ force: true, quizId: quizState.id, saved, shouldSync });

          router.push('/user/dashboard');
        }
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firestore, isLogin, localQuizzes, router, setItem, shouldForwardToDashboard, user, userId]);

  return showLoginPrompt ? (
    <Modal button={null} defaultOpen>
      <Box css={{ background: 'esa.background', padding: 10, borderRadius: 'md' }} layout='flexColumn'>
        <Typography css={{ paddingBottom: 6 }} type='h6'>
          Log in to continue.
        </Typography>

        <Typography css={{ paddingBottom: 6 }} type='body2'>
          You must create an account to continue.
        </Typography>

        <Box css={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'space-between', width: '100%' }}>
          <Link buttonVariant='filled' css={{ textDecoration: 'none' }} href='/user/login'>
            Log in
          </Link>
          <Button>Cancel</Button>
        </Box>
      </Box>
    </Modal>
  ) : null;
}
