'use client';

import { CONSTANTS } from '../../constants';
import posthog from 'posthog-js';
import { useEffect } from 'react';

export function Posthog() {
  useEffect(() => {
    if (window.location.hostname === 'localhost') {
      console.info('⚠️ Skipping Posthog install');
    } else {
      posthog.init(CONSTANTS.ENV.POSTHOG_KEY, {
        api_host: CONSTANTS.ENV.POSTHOG_HOST,
      });
    }
  }, []);

  return null;
}
